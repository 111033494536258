import { UPDATE_MANUFACTURER_USER } from '@fullcontour/shared-api';

async function update({
  variables: { variables },
  client,
  setErrorMessages,
  clearErrorMessages,
  navigate,
}) {
  const result = await client.mutate({
    mutation: UPDATE_MANUFACTURER_USER,
    variables,
  });
  const { updateManufacturerUser } = result.data;
  if (updateManufacturerUser.errorMessages.length) {
    setErrorMessages(updateManufacturerUser.errorMessages);
  } else {
    clearErrorMessages();
    navigate(`/users/${updateManufacturerUser.manufacturerUser.id}`);
  }
}

export default update;
