function initialValues(props) {
  const { data: { manufacturerUser = {} } = {}, currentUser } = props;
  return {
    id: manufacturerUser.id || null,
    firstName: manufacturerUser.firstName || '',
    lastName: manufacturerUser.lastName || '',
    organizationId: manufacturerUser.organization
      ? manufacturerUser.organization.id
      : currentUser.organizationId,
    language: manufacturerUser.language || 'en',
    roleId: manufacturerUser.role ? manufacturerUser.role.id : '',
    email: manufacturerUser.email || '',
    phone: manufacturerUser.phone || '',
  };
}

export default initialValues;
