import { useQuery } from '@apollo/client';
import {
  emailRenderer,
  GridContainer,
  LoadingOrError,
  nameValueGetter,
} from '@fullcontour/common';
import { GET_MANUF_USERS } from '@fullcontour/shared-api';
import PropTypes from 'prop-types';

function UsersListDataTable({ shortContainer = false, organizationId = null }) {
  let variables = {};

  if (organizationId) {
    variables = { ...variables, organizationId };
  }

  const { error, loading, data } = useQuery(GET_MANUF_USERS, {
    fetchPolicy: 'cache-and-network',
    variables,
  });

  if (loading || error)
    return <LoadingOrError error={error} loading={loading} title="Users" />;

  const gridOptions = {
    rowData: data.manufacturerUsers,
    defaultColDef: {
      floatingFilter: true,
    },
    columnDefs: [
      {
        field: 'name',
        minWidth: 150,
        sort: 'asc',
        filter: 'agTextColumnFilter',
        valueGetter: nameValueGetter,
        cellStyle: { color: '#1B77C8', cursor: 'pointer' },
        onCellClicked: (params) => {
          params.context.navigate(`/users/${params.data.id}`);
        },
      },
      {
        field: 'email',
        minWidth: 200,
        filter: 'agTextColumnFilter',
        cellRenderer: emailRenderer,
      },
      {
        field: 'role.name',
        headerName: 'Role',
        minWidth: 150,
        filter: 'agTextColumnFilter',
        suppressHeaderMenuButton: true,
      },
    ],
  };

  return (
    <GridContainer
      rootVal="users"
      gridOptions={gridOptions}
      to="/users/new"
      content="New User"
      shortContainer={shortContainer}
    />
  );
}

UsersListDataTable.propTypes = {
  shortContainer: PropTypes.bool,
  organizationId: PropTypes.string,
};

export default UsersListDataTable;
