import { ShowEditButton } from '@fullcontour/common';
import PropTypes from 'prop-types';
import { memo } from 'react';

function ShowHeader({
  title,
  editLink,
  leftContent = null,
  rightContent = null,
  logoUrl = null,
  code = null,
}) {
  return (
    <div className="section box">
      <div className="is-flex is-flex-direction-row is-align-items-flex-start">
        <div className="is-flex is-flex-direction-row">
          {logoUrl && (
            <figure
              className="image mr-4 is-128x128 p-2 is-flex is-flex-direction-column is-justify-content-center"
              style={{ background: '#ffffff' }}
            >
              <img src={logoUrl} alt={title} />
            </figure>
          )}
        </div>
        <div className="column">
          <div
            style={{
              width: 'calc(100% - 80px - 1rem)',
            }}
          >
            <h3
              className="title is-3 has-text-dark"
              style={{ marginBottom: '0.25em' }}
            >
              {title}
            </h3>
            {code && (
              <h6
                className="title is-6 has-text-dark mb-2 mt-0"
                style={{ marginBottom: '0.25em', marginTop: 0 }}
              >
                {code}
              </h6>
            )}
            {leftContent}
          </div>
        </div>
        <div className="is-flex is-flex-direction-column is-align-items-flex-end is-justify-content-flex-start has-text-right">
          <ShowEditButton editLink={editLink} />
          {rightContent}
        </div>
      </div>
    </div>
  );
}

ShowHeader.propTypes = {
  title: PropTypes.string.isRequired,
  editLink: PropTypes.string.isRequired,
  logoUrl: PropTypes.string,
  leftContent: PropTypes.node,
  rightContent: PropTypes.node,
  code: PropTypes.string,
};

export default memo(ShowHeader);
