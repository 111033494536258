import { useQuery } from '@apollo/client';
import { LoadingOrError } from '@fullcontour/common';
import { GET_MANUF_USER } from '@fullcontour/shared-api';
import { useParams } from 'react-router';
import { ShowContainer } from '../../../shared/ShowElements';

function UserShowInner() {
  const { id } = useParams();

  const { error, loading, data } = useQuery(GET_MANUF_USER, {
    fetchPolicy: 'cache-and-network',
    variables: { id },
  });

  if (loading || error)
    return <LoadingOrError error={error} loading={loading} title="User" />;

  const user = data.manufacturerUser;

  return (
    <ShowContainer
      title={`${user.firstName} ${user.lastName}`}
      editLink={`/users/${id}/edit`}
      createdAt={user.createdAt}
      updatedAt={user.updatedAt}
      subHeader={user.email}
    />
  );
}

export default UserShowInner;
