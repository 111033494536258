import IntegrationsListQueryWrapper from '@fullcontour/common/src/components/views/Integrations/IntegrationsList/IntegrationsListQueryWrapper';
import IntegrationForm from '@fullcontour/common/src/components/views/Integrations/IntegrationForm/IntegrationForm';

const routes = [
  {
    path: ['/organizations/integrations'],
    component: IntegrationsListQueryWrapper,
    sl: false,
    so: true,
  },
  {
    path: [
      '/organizations/integrations/:action',
      '/organizations/integrations/:id/:action',
    ],
    component: IntegrationForm,
    sl: false,
    so: true,
  },
];

export default routes;
