import { gql } from '@apollo/client';

const CustomerUserInfo = gql`
  fragment CustomerUserInfo on CustomerUser {
    id
    firstName
    lastName
    email
    phone
    language
    state
    designTypeIds
    source
    role {
      id
      name
      level
      scopeToLocation
      assignmentOrganizations
      assignmentLocations
      assignmentDesignTypes
    }
    organization {
      id
      name
      slug
      shortName
    }
    location {
      id
      name
      slug
    }
    createdAt
    updatedAt
  }
`;

export default CustomerUserInfo;
