import {
  FormGridWrap,
  FormSection,
  LoadingOrError,
  RoleSelect,
  UserFormCommonFields,
  withFormWrap,
} from '@fullcontour/common';
import { GET_MANUF_USER } from '@fullcontour/shared-api';
import { Field, Formik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { flowRight } from 'lodash-es';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import { useApolloClient, useQuery } from '@apollo/client';
import { initialValues, schema, create, update } from './helpers/Manufacturer';
import { getVariables, skipQuery } from './helpers/queryOptions';

function UserFormManufacturer({
  initialValues = () => {},
  schema = () => {},
  match = {},
  separateId = null,
  currentUser = {},
  ...rest
}) {
  const client = useApolloClient();
  const { action } = useParams();
  const { data, loading, error } = useQuery(GET_MANUF_USER, {
    skip: skipQuery ? skipQuery({ match }) : false,
    variables: getVariables ? getVariables({ match }) : {},
    fetchPolicy: 'cache-and-network',
  });

  if (loading || error)
    return <LoadingOrError error={error} loading={loading} title="User" />;

  function handleSubmit(values) {
    const variables = {
      variables: { input: { input: values, clientMutationId: uuidv4() } },
    };

    if (separateId && match.params.action !== 'new') {
      variables.variables.input.id = values.id;
    }

    if (separateId) {
      delete variables.variables.input.input.id;
    }

    if (match.params.action && match.params.action === 'new') {
      create({ variables, client, ...rest });
    } else {
      update({ variables, client, ...rest });
    }
  }
  return (
    <Formik
      initialValues={initialValues({ data, currentUser })}
      validationSchema={schema()}
      // enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      <FormGridWrap match={match}>
        <UserFormCommonFields />
        <FormSection title="Assignment">
          <Field
            name="roleId"
            required
            disabled={action === 'edit'}
            component={RoleSelect}
            modelscope="ManufacturerUser"
          />
        </FormSection>
      </FormGridWrap>
    </Formik>
  );
}
UserFormManufacturer.propTypes = {
  initialValues: PropTypes.func,
  schema: PropTypes.func,
  match: PropTypes.object,
  update: PropTypes.func,
  create: PropTypes.func,
  separateId: PropTypes.string,
  currentUser: PropTypes.object,
};
export default flowRight(withFormWrap({ initialValues, schema }))(
  UserFormManufacturer,
);
